
import { mapActions, mapGetters } from 'vuex'
import constants from '@/utils/constants'

export default {
  data:() => ({
    typeOptions: constants.typeOptions,
    default: {
      parent: [{ label: '- Root -', value: 0 }],
    },
  }),

  computed: {
    ...mapGetters('data', ['trackingTopics']),

    trackingTopicsOptions () {
      if (this.lodash.get(this, 'trackingTopics', []).length === 0) {
        return []
      }

      const trackingTopics = this.lodash.concat(
        this.default.parent,
        this.trackingTopics.map((trackingTopic) => ({
          label: trackingTopic.title,
          value: trackingTopic.id,
        })),
      )

      return trackingTopics
    },
    trackingTopicsParentOptions () {
      if (this.lodash.get(this, 'trackingTopics', []).length === 0) {
        return []
      }

      return this.trackingTopics.map((trackingTopic) => ({
        label: trackingTopic.title,
        value: trackingTopic.id,
      }))
    },
  },

  methods: {
    ...mapActions('data', ['fetchTrackingTopics']),
  },

  beforeMount () {
    if (this.lodash.get(this, 'trackingTopics', []).length === 0) {
      this.fetchTrackingTopics()
    }
  },
}
