<template>
  <div>
    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="value.title"
          class="w-full mt-4"
          label="Title"
          v-validate="'required'"
          name="title"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('title')">
          {{ errors.first('title') }}
        </span>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vs-component vs-con-input-label vs-input w-full mt-4 vs-input-primary">
          <label class="vs-input--label">Parent</label>
          <v-select
            v-model="value.parent_id"
            :options="trackingTopicsParentOptions"
            :reduce="topic => topic.value"
            name="parent"/>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('parent')">
            {{ errors.first('parent') }}
          </span>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vs-component vs-con-input-label vs-input w-full mt-4 vs-input-primary">
          <label class="vs-input--label">Type</label>
          <div class="w-full mt-2">
            <vs-checkbox
              v-model="value.types"
              vs-value="inbound"
              class="inline-flex"
              color="primary"
              name="types"
              v-validate="'required'">
              Inbound
            </vs-checkbox>
            <vs-checkbox
              v-model="value.types"
              vs-value="outbound"
              class="inline-flex"
              color="primary"
              name="types"
              v-validate="'required'">
              Outbound
            </vs-checkbox>
          </div>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('types')">
            {{ errors.first('types') }}
          </span>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vs-component vs-con-input-label vs-input w-full mt-4 vs-input-primary">
          <label class="vs-input--label">Status</label>
          <v-select
            v-model="value.is_active"
            :clearable="false"
            :options="statusOptions"
            :reduce="status => status.value"
            v-validate="'required'"
            name="status"/>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('status')">
            {{ errors.first('status') }}
          </span>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="value.id">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.created_at)"
          class="w-full mt-4"
          label="Created At"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.updated_at)"
          class="w-full mt-4"
          label="Updated At"
          disabled/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import constants from '@/utils/constants'
import { default as infoMixin } from '@/mixins/infoMixin'
import { default as formMixin } from '@/mixins/tracking-topic/formMixin'

export default {
  name: 'TrackingTopicGeneral',
  mixins: [infoMixin, formMixin],
  props: {
    value: {
      type: Object,
    },
  },
  data:() => ({
    typeOptions: constants.typeOptions,
  }),
  computed: {
    ...mapGetters('data', ['trackingTopics']),
    roleOptions () {
      return this.roles.map((role) => ({
        label: role.name,
        value: role.id,
      }))
    },
  },
  methods: {
    ...mapActions('data', ['fetchTrackingTopics']),
  },

  beforeMount () {
    if (this.lodash.get(this, 'trackingTopics', []).length === 0) {
      this.fetchTrackingTopics()
    }
    // console.log({ route: this.$route, currentClub: this.currentClub })
    // console.log(this.lists)
  },
}
</script>
